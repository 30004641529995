<template>
  <div v-on:scroll="onScroll" class="home general">
    <h1 class="section-title">
      Lista de Géneros
      <va-button
        color="info"
        @click="createGenre()"
      >
        Crear Género
      </va-button>
    </h1>

    <va-data-table
        class="ekitable"
        :items="genres"
        :columns="columns"
        clickable
        no-data-html="<div class='no-data'>Cargando pistas...</div>"
        :sticky-header="true"
        :loading="false"
        loading-text=""
    >
      <template #headerPrepend>
        <tr>
          <th colspan="6">
            <va-input class="search-bar" title="Buscar Canciones" v-title placeholder="¿Qué canción buscas?" v-on:keyup.enter="onEnter" v-on:focusout="onEnter" v-model="search">
              <template #prependInner>
                <font-awesome-icon :icon="['fa-solid', 'search']" />
              </template>
            </va-input>
          </th>
        </tr>
      </template>

      <template #header(name)="{ label }">
        <div class="label ekiflex flex-vcenter flex-hbetween" title="Ordenar por Título" v-title @click="sortList('name')">
          <div>
            {{ label }}
          </div>
          <font-awesome-icon :icon="['fa-solid', getIcon('name')]" size="1x"/>
        </div>
      </template>

      <template #cell(image)="{ rowData, rowIndex }">
        <va-avatar class="border-square"
                  size="small"
                  :src="rowData.image"/>
      </template>

      <template #cell(name)="{ rowData }">
        <router-link
          v-if="rowData.id"
          title="Ir al Detalle del Género" v-title
          :to="{ name: 'genreDetail', params: { id: rowData.id } }"
          class="song-title linked"
        >{{ rowData.name }}</router-link>
        <div v-else class="song-title">{{ rowData.name }}</div>
      </template>

      <template #cell(active)="{ row, isExpanded, rowIndex }">
        <div class="actions">
          <va-switch
            v-model="genres[rowIndex].active"
            @update:modelValue="saveGenre(rowIndex)"
            title="Cambiar estado" 
            v-title color="success"
            class="action"
          />
          <va-button
            color="#ffffff"
            class="action"
            title="Editar género" 
            v-title
            @click="row.toggleRowDetails()"
            :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
            preset="secondary"
          >
            Editar
          </va-button>
        </div>        
      </template>

      <template #expandableRow="{ rowData }">
        <div class="edit-container">
          <div class="config">
            <div class="title">Edición</div>
            <va-input
              v-model="genres[findIndex(rowData.id ?? rowData.localId)].name"
              class="input"
              label="Nombre"
              title="Cambiar nombre" 
              v-title
              placeholder="Nombre"
            />
            <va-input
              v-model="genres[findIndex(rowData.id ?? rowData.localId)].description"
              class="input"
              type="textarea"
              label="Descripción"
              title="Cambiar descipción" 
              v-title
              placeholder="Descripción"
            />
            
            <div class="field c-1-3">
              <span class="va-title">Foto</span>
              <va-file-upload
                dropzone
                type="single"
                file-types="image/*"
                v-model:model-value="newImg"
                @file-added="checkFile(findIndex(rowData.id ?? rowData.localId))"
                @file-removed="checkFile(findIndex(rowData.id ?? rowData.localId))"
                hideFileList
              >
              <div class="upload-area">
                <div class="hover-info">
                  Cambiar imagen
                </div>
                <img
                  :src="rowData.image"
                  width="100"
                  height="100"
                >
              </div>
              </va-file-upload>
            </div>
            <div class="field c-1-3">
              <span class="va-title">Rating</span>
              <va-counter
                :min="0"
                :max="100"
                :step="1"
                v-model="genres[findIndex(rowData.id ?? rowData.localId)].rate"
                manual-input
                class="input"
                title="Cambiar rating" 
                v-title
              />   
            </div>            
            <div class="field c-1-3">
              <span class="va-title">¿Activo?</span>
              <va-switch
                v-model="genres[findIndex(rowData.id ?? rowData.localId)].active"
                title="Cambiar estado" 
                v-title color="success"
                class="input"
              />
            </div>
            
            <va-button
              color="warning"
              class="btn"
              @click="saveGenre(findIndex(rowData.id ?? rowData.localId))"
            >
              Guardar
            </va-button>
            <va-button
              color="danger"
              class="btn"
              v-if="rowData.id"
              @click="deleteGenre(findIndex(rowData.id))"
            >
              Eliminar
            </va-button>
          </div>
          <div class="preview">
            <div class="title">Vista Previa</div>
            <div class="app-bg ekiflex flex-hcenter">
              <div title="Ir al detalle del Género" v-title class="genre-card ekiflex flex-vcenter">
                <div class="genre-thumb">
                  <img :lazy-src="require('@/assets/background.png')"
                        :src="rowData.image"
                      />
                </div>
                <p class="genre-title">{{ rowData.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </va-data-table>
  </div>
</template>

<script>
import requester from '../../services/requester';

export default {
  name: 'GenresList',

  data() {
    return {
      genres: [],
      genresPage: 1,
      isLoading: false,
      continueLoading: true,
      search: "",
      columns: [
        {key: 'image', sortable: false, label: ' ', width: '35px'},
        {key: 'name', sortable: false, label: 'Título'},
        {key: 'rate', sortable: false, label: 'Rating'},
        {key: 'active', sortable: false, label: 'Activo'},
      ],
      sortings: new Map(),
      sortingOrder: null,
      sortBy: null,
      localId: 0,
      newImg: []
    };
  },

  methods: {
    onEnter(){
      this.genresPage = 1;
      this.getGenresFromAPI();
    },

    getIcon(sortBy){
      const sortingOrder = this.sortings.get(sortBy);
      return !sortingOrder?'sort':sortingOrder==='ASC'?'sort-up':'sort-down';
    },

    setSorting({sortBy, sortingOrder}){
      if(this.sortBy === sortBy && this.sortingOrder === sortingOrder) {
        return;
      }
      this.sortBy = sortBy;
      this.sortingOrder = sortingOrder;
      this.onEnter();
    },

    sortList(sortBy){
      const sortingOrder = this.sortings.get(sortBy) === 'ASC'?'DESC':'ASC';
      this.sortings.set(sortBy,sortingOrder)
      this.setSorting({sortBy, sortingOrder})
    },
    getGenres(){
      try {
        this.getGenresFromAPI();
      } catch (error) {
        console.log(error);
      }
    },
    onScroll(event){
      if(this.isLoading || !this.continueLoading){
        return;
      }
      const {target} = event;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.getGenresFromAPI(true);
      }
    },
    async getGenresFromAPI(isScroll=false){
      try{
        this.isLoading = true;
        const data = await requester.Get(`/get-generos/${this.genresPage}/?name=${this.search}&sortBy=${this.sortBy}&sortingOrder=${this.sortingOrder}`, true);
        if(data.status === 200){
          this.genres = !isScroll ? data.data.generos.map((item, index) => {return {...item, pos: index}}) : this.genres.concat(data.data.generos.map((item, index) => {return {...item, pos: index}}));
          console.log(this.genres);
          if(data.data.generos.length>0){
            this.genresPage++;
            this.continueLoading = true;
          }
          else{
            this.continueLoading = false;
          }
        }
      }
      catch (e){
        console.log(e);
      }
      finally {
        this.isLoading = false;
      }
    },

    createGenre(){
      const data = {
        localId: "localId"+this.localId,
        name: "Nuevo Género",
        rate: 1,
        active: false,
        description: '',
        image: null,
      }
      this.genres.splice(0, 0, data);
      this.localId++;
    },

    async saveGenre(index){
      if(index >= 0 && index < this.genres.length){
        console.log("guardar: ", index);

        const res = await requester.Put('/save-genero', true, this.genres[index]);
        console.log("saving", res);
        if(res.status == 200){
          this.genres[index] = res.data;
          console.log("guardado exitosamente");
        }
      }                  
    },

    async deleteGenre(index){
      if(index >= 0 && index < this.genres.length){
        const res = await requester.Delete('/delete-genero/'+this.genres[index].id, true);
        console.log("deleting", res);
        if(res.status == 200){
          this.genres.splice(index, 1);
          console.log("borrado exitosamente");
        }
      }                  
    },

    checkFile(index){
      let reader = new FileReader();
      reader.readAsDataURL(this.newImg)
      const me = this;
      reader.onload = function() {
        me.genres[index].image = reader.result;
      };
    },

    findIndex(id){
      if(!id.includes("localId")){
        return this.genres.findIndex((el) => el.id == id);
      }else{
        return this.genres.findIndex((el) => el.localId == id);
      }
    }
  },

  created() {
    this.search = this.$route.query.q ?? "";
    this.onEnter();
  },
}
</script>

<style lang="scss" src="./genres.scss" scoped></style>
