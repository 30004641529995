<template>
  <div v-on:scroll="onScroll" class="home general">
    <h1 class="section-title">
      Lista de Banners
      <va-button
          color="info"
          @click="createArtist()">
        Crear Banner
      </va-button>
    </h1>

    <va-data-table
        class="ekitable"
        :items="banners"
        :columns="columns"
        clickable
        no-data-html="<div class='no-data'>Cargando...</div>"
        :sticky-header="true"
        :loading="false"
        loading-text="">

      <template #cell(name)="{ rowData }">
        <div class="song-title">{{ rowData.labels.title }}</div>
      </template>

      <template #cell(active)="{ row, isExpanded, rowIndex }">
        <div class="actions">
          <va-switch
              v-model="banners[rowIndex].active"
              @update:modelValue="saveArtist(rowIndex)"
              title="Cambiar estado"
              v-title color="success"
              class="action"
          />
          <va-button
              color="#ffffff"
              class="action"
              title="Editar"
              v-title
              @click="row.toggleRowDetails()"
              :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
              preset="secondary">Editar
          </va-button>
        </div>
      </template>

      <template #cell(status)="{ rowData }">
        <div v-if="rowData.status === true" class="song-title"><va-chip color="success">Privado</va-chip></div>
        <div v-else class="song-title"><va-chip color="warning">Publico</va-chip></div>
      </template>

      <template #cell(subscribe)="{ rowData }">
        <div v-if="rowData.subscribed == 2" class="song-title"><va-chip color="success">Con suscripción</va-chip></div>
        <div v-else-if="rowData.subscribed == 1" class="song-title"><va-chip color="warning">Sin suscripción</va-chip></div>
        <div v-else-if="rowData.subscribed == 0" class="song-title"><va-chip color="primary">General</va-chip></div>
      </template>

      <template #expandableRow="{ rowData }">
        <div class="edit-container">
          <div class="config">
            <div class="title">Edición</div>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.title"
                class="input"
                label="Titulo"
                title="Cambiar nombre"
                v-title
                placeholder="Nombre"/>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.subtitle"
                class="input"
                type="textarea"
                label="Subtitulo"
                title="Cambiar Subtitulo"
                v-title
                placeholder="Subtitulo"/>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.bg_img"
                class="input"
                type="textarea"
                label="Url imagen de fondo"
                title="Cambiar url imagen de fondo"
                v-title
                placeholder="Url imagen de fondo"/>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.btn.label"
                class="input"
                type="textarea"
                label="Titulo botón"
                title="Cambiar Titulo botón"
                v-title
                placeholder="Titulo botón"/>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.btn.to"
                class="input"
                type="textarea"
                label="Link boton"
                title="Cambiar Link botón"
                v-title
                placeholder="Link botón"/>
            <va-input
                v-model="banners[findIndex(rowData.id ?? rowData.localId)].labels.btn.target"
                class="input"
                label="Target"
                title="Target"
                v-title
                placeholder="_blank|_self"/>
            <div class="row">
              <div class="flex flex-col md6">
                <div class="item">
                  <va-switch
                      v-model="banners[findIndex(rowData.id ?? rowData.localId)].active"
                      color="success"/>
                </div>
              </div>
              <div class="flex flex-col md6 va-switch-custom">
                <div class="item">
                  <va-switch
                      v-model="banners[findIndex(rowData.id ?? rowData.localId)].status"
                      label="Visible"
                      true-label="Privado"
                      false-label="Publico"
                      color="success"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="flex flex-col md6 va-switch-custom">
                <div class="item">
                  <va-option-list
                      class="input"
                      v-model="banners[findIndex(rowData.id ?? rowData.localId)].bannerLanguage.locale"
                      type="radio"
                      :options="['es', 'en', 'br']"/>
                </div>
              </div>
              <div class="flex flex-col md6 va-switch-custom">
                <div class="item">
                  <va-option-list
                      v-model="banners[findIndex(rowData.id ?? rowData.localId)].subscribed"
                      type="radio"
                      color="primary"
                      :options=options
                      value-by="altValue"
                      :text-by="(option) => option.altText"/>
                </div>
              </div>
            </div>
            <va-button
                color="warning"
                class="btn"
                @click="saveArtist(findIndex(rowData.id ?? rowData.localId))">Guardar
            </va-button>
            <va-button
                color="danger"
                class="btn"
                v-if="rowData.id"
                @click="deleteArtist(findIndex(rowData.id))">Eliminar
            </va-button>
          </div>
        </div>
      </template>
    </va-data-table>
  </div>
</template>

<script>
import requester from '@/services/requester';
export default {
  name: 'BannersList',

  data() {
    return {
      banners: [],
      columns: [
        {key: 'name', sortable: false, label: 'Título'},
        {key: 'active', sortable: false, label: 'Activo'},
        {key: 'status', sortable: false, label: 'Visible'},
        {key: 'subscribe', sortable: false, label: 'Mostrar'}
      ],
      isLoading: false,
      continueLoading: true,
      localId: 0,
      options : [
        {
          value: 0,
          text: "General",
          altText: "General",
          altValue: 0
        },
        {
          value: 1,
          text: "Sin suscripción",
          altText: "Sin suscripción",
          altValue: 1
        },
        {
          value: 2,
          text: "Con suscripción",
          altText: "Con suscripción",
          altValue: 2
        },
      ]
    };
  },

  methods: {
    onEnter(){
      this.getArtistsFromAPI();
    },

    onScroll(event) {
      if(!this.continueLoading){
        return;
      }
      if (!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getArtistsFromAPI(true);
        }
      }
    },

    getArtists(){
      try {
        this.getArtistsFromAPI();
      } catch (error) {
        console.log(error);
      }
    },

    async getArtistsFromAPI(){
      try{
        this.isLoading = true;
        const data = await requester.Get(`/get-banners`,true);
        if (data.data.status === 200) {
          if(data.data.data.length > 0){
            this.banners = data.data.data;
            this.continueLoading = true;
          }
          else{
            this.continueLoading = false;
          }
        }
      }
      catch (e){
        console.log(e);
      }
      finally {
        this.isLoading = false;
      }
    },

    createArtist(){
      const data = {
        localId: "localId" + this.localId,
        labels: {
          btn: {
            to: "Nuevo",
            label: "Nuevo",
            target: "_blank"
          },
          title: "Nuevo Banner",
          bg_img: "Link Imagen",
          subtitle: "Nuevo Subtitule",
        },
        bannerLanguage: {
          locale: "es",
        },
        active: true,
        status: true,
        subscribed: 0
      }
      this.banners.splice(0, 0, data);
      this.localId++;
    },

    async saveArtist(index){
      if(index >= 0 && index < this.banners.length){
        const res = await requester.Put('/save-banner',true, this.banners[index]);
        if(res.status == 200){
          this.banners[index] = res.data;
        }
      }
    },

    async deleteArtist(index){
      if(index >= 0 && index < this.banners.length){
        const res = await requester.Delete('/delete-banner/' + this.banners[index].id,true);
        if(res.status == 200){
          this.banners.splice(index, 1);
        }
      }
    },

    findIndex(id){
      if(!id.includes("localId")){
        return this.banners.findIndex((el) => el.id == id);
      }else{
        return this.banners.findIndex((el) => el.localId == id);
      }
    }
  },

  created() {
    this.onEnter();
  },

}

</script>

<style lang="scss" src="./banner.scss" scoped></style>