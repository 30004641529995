import store from '../store'
const axios = require('axios').default;
const axiosRetry = require('axios-retry');
const encrypter = require('crypto-js');

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    transformRequest: [function (data, headers){
        if(data){
            if(!headers.isPublic){
                const key = store.getters['user/cryptoKey'];
                const crypted = encrypter.AES.encrypt(
                    JSON.stringify(data),
                    key
                ).toString();
                const dataToSend = { data: crypted };
                return JSON.stringify(dataToSend);
            }else{
                const crypted = encrypter.AES.encrypt(
                    JSON.stringify(data),
                    process.env.VUE_APP_CRIPTO_KEY
                ).toString();
                const dataToSend = { data: crypted };
                return JSON.stringify(dataToSend);
            }
        }else{
            return data;
        }
    }],
    transformResponse: [function (data, headers){
        try {
            if(!data){
                return {error: "emptyData", data};
            }
            let dataObj;
            if(!headers.isPublic){
                const key = store.getters['user/cryptoKey'];
                dataObj = JSON.parse(encrypter.AES.decrypt(
                    data,
                    key
                ).toString(encrypter.enc.Utf8));
            }else{
                dataObj = JSON.parse(encrypter.AES.decrypt(
                    data,
                    process.env.VUE_APP_CRIPTO_KEY
                ).toString(encrypter.enc.Utf8));
            }
            //info message will be packaged in the body data response
            if(dataObj){
                const {showInfoMessage} = dataObj;
                if(showInfoMessage){
                    const {title, message, timeVisible, type} = showInfoMessage;
                    store.dispatch("notifs/setUpNotification", { data: {
                            title,
                            message,
                            type,
                            timeVisible,
                            position: 'top-center',
                        }}, {root:true});
                }
            }
            
            return dataObj ?? data;
        } catch (error) {
            return {error: "decryptError", data};
        }
     }]
});

client.interceptors.response.use((response) => {
    return response
}, async (error) => {
    const origitalRequest = error.config;
    if (error.response) {
        if (error.response.status === 403 && !origitalRequest._retry) {
            try {
                origitalRequest._retry = true;
                await store.dispatch('user/refreshToken');
                return client(origitalRequest);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    }
    return Promise.reject(error);
});

axiosRetry(client, { 
    retries: 3, 
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: e => { return e.response.status === 403 },
    onRetry: (retryCount, error, requestConfig) => {
        const tokens = store.getters['user/tokens'];
        requestConfig.headers.Authorization = "Bearer " +tokens.token;
    }
});

const outClient = axios.create();

const MakeHeader = (withAuth, addHeader) => {
    var header = {
        "Content-Type": "application/json"
    };

    const tokens = store.getters['user/tokens'];

    if(withAuth){
        header = {
            ...header,
            ...{"Authorization": "Bearer "+tokens.token},
            ...addHeader
        };
    }else{
        header = {
            ...header,
            ...addHeader
        };
    }

    return header;
}

const Get = (route, withAuth = false, addHeader = {}) => {
    var head = MakeHeader(withAuth, addHeader);
    return client.get(route, {
        headers: head
    });
}

const OutGet = (route, addHeader = {}) => {
    var head = MakeHeader(false, addHeader);
    return outClient.get(route, {
        headers: head
    });
}

const Put = (route, withAuth = false, body = {}, addHeader = {}) => {
    var head = MakeHeader(withAuth, addHeader);
    return client.put(route, body, {
        headers: head
    });
}

const Post = (route, withAuth = false, body = {}, addHeader = {}) => {
    var head = MakeHeader(withAuth, addHeader);
    return client.post(route, body, {
        timeout: 20000,
        headers: head
    });
}

const Delete = (route, withAuth = false, addHeader = {}) => {
    var head = MakeHeader(withAuth, addHeader);
    return client.delete(route, {
        headers: head
    });
}

export default {
    Get, Put, Post, Delete, MakeHeader,
    OutGet
}
