<template>
  <div class="fondo">
    <va-form class="form hide-scroll scroll"
             tag="form"
             @submit.prevent="handleSubmit">
      <img
          class="main-icon"
          :src="require('@/assets/logo.png')"
          alt="ekilogo.png"
      />
      <h1 class="login-title">Ecuakaraoke Administrador</h1>
      <va-input
          class="form-inputs"
          color="#640899"
          v-model="userData.email"
          type="email"
          label="Email"
          placeholder="Email"
      />
      <va-input
          class="form-inputs"
          color="#640899"
          v-model="userData.password"
          :type="isPasswordVisible ? 'text' : 'password'"
          label="Contraseña"
          placeholder="Contraseña"
      >
        <template #appendInner>
          <font-awesome-icon
              class="show-password"
              title="Mostrar Contraseña" v-title
              @click="isPasswordVisible = !isPasswordVisible"
              :icon="['fa-solid', isPasswordVisible ? 'eye-slash' : 'eye']"
          />
        </template>
      </va-input>
      <va-button title="Ingresar al Sistema" v-title type="submit" style="width: 60%">
        Ingresar
      </va-button>
      <va-inner-loading v-if="isLoading" ref="loading" loading :size="75">
      </va-inner-loading>
    </va-form>
  </div>
</template>

<script>
import {VaButton, VaForm, VaInnerLoading, VaInput} from "vuestic-ui";
const md5 = require('md5');

export default {
  name: "AuthenticateView",
  components: {VaInnerLoading, VaButton, VaInput, VaForm},

  data() {
    return {
      isLoading: false,
      isPasswordVisible: false,
      userData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      try {
        this.isLoading = true;
        const {redirect, q, params} = this.$route.query;
        await this.$store.dispatch("user/login", {
          email: this.userData.email,
          password: md5(this.userData.password),
          redirect,
          q,
          params
        });
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    handleSubmit() {
      this.login();
    }
  },
};
</script>

<style lang="scss" src="./authenticate.scss" scoped></style>
