import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import routes from './routes';

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active-nav",
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = store.getters['user/apiUser'];
  store.dispatch("utils/setUpCrumbDetail", { crumbLabel: '' });
  if(requiresAuth){
    if(!currentUser){
      next({ name: "authenticate", query: {redirect: to.name, q: JSON.stringify(to.query), params: JSON.stringify(to.params)}});
    }
  }else{
    if(currentUser){
      next({ name: "home" });
    }
  }
  next();
});

export default router;
