<template>
  <div class="home general" v-on:scroll="onScroll">
    <h1 class="section-title">
      TOP canciones
    </h1>

    <va-data-table
        class="ekitable"
        :items="songs"
        :columns="columns"
        clickable
        no-data-html="<div class='no-data'>Cargando pistas...</div>"
        :sticky-header="true"
        :loading="false"
        allow-create
        loading-text=""
    >
      <template #cell(title)="{ rowData }">
        <router-link v-if="rowData.songHis"
                     title="Ir a Detalle de la canción" v-title
                     :to="{ name: 'songDetail', params: { id: rowData.songHis.id } }"
                     class="table-span linked"
        >{{ rowData.songHis.title }}
        </router-link>
      </template>

    </va-data-table>
  </div>
</template>
<script>
import requester from "@/services/requester";

export default {
  data() {
    return {
      page: 1,
      isLoading:false,
      songs:[],
      continueLoading: true,

      columns:[
        {key: 'count', sortable: false, label: 'Reproducciones'},
        {key: 'title', sortable: false, label: 'Canción'},
        {key: 'songHis.rate', sortable: false, label: 'Rate'},
      ]
    }
  },
  methods: {
    onEnter() {
      this.page = 1;
      this.getFromApi();
    },
    async getFromApi(isScroll = false) {
      this.isLoading = true;
      const data = await requester.Get(`/get-top-songs/${this.page}`, true);
      if (data.status === 200) {
        if (!isScroll) {
          this.songs = data.data.songs;
        } else {
          this.songs = this.songs.concat(data.data.songs);
        }
        if (data.data.songs.length > 0) {
          this.page++;
          this.continueLoading = true;
        } else {
          this.continueLoading = false;
        }
      }
      this.isLoading = false;
    },
    onScroll(event) {
      if (!this.continueLoading) {
        return;
      }
      if (!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getFromApi(true);
        }
      }
    },
  },
  created() {
    this.onEnter();
  }
}

</script>