<template>
    <div class="home general" v-on:scroll="onScroll">
      <h1 class="section-title">
        Lista de Preguntas Frecuentes
        <va-button
          color="info"
          class="faq-btn"
          @click="createFaq()"
        >
          Crear FAQ
        </va-button>
      </h1>
      <va-accordion
        class="eki-accordion"
        multiple
        >
        <va-collapse
            v-for="(faq, index) in faqs"
            :key="index"
            class="faq-title"
            :header="'(Editar) '+faq.question"
        >
            <div class="faq-answer">
              <div class="config">
                <div class="title">Edición</div>
                <va-input
                  v-model="faqs[index].question"
                  class="faq-input"
                  label="Pregunta"
                  placeholder="Pregunta"
                />
                <va-input
                  v-model="faqs[index].answer"
                  class="faq-input"
                  type="textarea"
                  label="Respuesta"
                  placeholder="Respuesta"
                />
                <va-button
                  color="warning"
                  class="faq-btn"
                  @click="saveFaq(index)"
                >
                  Guardar
                </va-button>
                <va-button
                  color="danger"
                  class="faq-btn"
                  v-if="faq.id"
                  @click="deleteFaq(index)"
                >
                  Eliminar
                </va-button>
                <va-button
                  color="info"
                  class="faq-btn"
                  v-if="faq.id"
                  @click="duplicateFaq(index)"
                >
                  Duplicar
                </va-button>
              </div>
              <div class="preview">
                <div class="title">Vista Previa</div>
                <va-accordion class="eki-accordion">
                  <va-collapse
                      class="faq-title"
                      :header="faq.question"
                  >
                      <div class="faq-example" v-html="faq.answer">
                      </div>
                  </va-collapse>
                </va-accordion>
              </div>
            </div>
        </va-collapse>
      </va-accordion>
    </div>
  </template>
  
  <script>  
  import requester from '../../services/requester';
  
  export default {
    name: "FaqsList",

    data() {  
      return {
        faqs: []
      };
    },
  
    created() {
      this.getFaqs();
    },
  
    methods: {
      async getFaqs(){
        const faqs = await requester.Get('/get-faqs', true);
        if(faqs.status == 200){
            this.faqs = faqs.data.faqs;
        }            
      },
      async saveFaq(index){
        if(index >= 0 && index < this.faqs.length){
          const res = await requester.Put('/save-faq', true, this.faqs[index]);
          console.log("saving", res);
          if(res.status == 200){
            this.faqs[index] = res.data;
            console.log("guardado exitosamente");
          }
        }
                  
      },
      async deleteFaq(index){
        const res = await requester.Delete('/delete-faq/'+this.faqs[index].id, true);
        console.log("deleting", res);
        if(res.status == 200){
          this.faqs.splice(index, 1);
          console.log("borrado exitosamente");
        }
      },
      async duplicateFaq(index){
        if(index >= 0 && index < this.faqs.length){
          const data = {
            question: this.faqs[index].question,
            answer: this.faqs[index].answer
          }
          const res = await requester.Put('/save-faq', true, data);
          console.log("duplicating", res);
          if(res.status == 200){
            this.faqs.splice(index+1, 0, res.data);
            console.log("creado exitosamente");
          }
        }                  
      },
      createFaq(){
        const data = {
          question: "Nueva Pregunta",
          answer: "Nueva Respuesta"
        }
        this.faqs.splice(0, 0, data);
      },
    }
  };
  </script>
  
  <style lang="scss" src="./faqs.scss" scoped></style>
  