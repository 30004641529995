import requester from '../services/requester';
import encrypter from "crypto-js";
import router from '../router';

const state = {
    apiUser: null,
}

const getters = {
    apiUser(state){
        return state.apiUser;
    },
    tokens(state){
        if(state.apiUser){
            return {
                token: state.apiUser.token,
                refreshToken: state.apiUser.refreshToken
            };
        }
        return null;        
    },
    cryptoKey(state){
        if(state.apiUser){
            return state.apiUser.key;
        }
        return process.env.VUE_APP_CRIPTO_KEY; 
    }
};

const mutations = {
    setApiUser(state, appUser){
        localStorage.setItem('adminUser', JSON.stringify(appUser));
        state.apiUser = appUser;
    },
    setRefreshKeys(state, freshData){
        state.apiUser.token = freshData.token;
        state.apiUser.key = freshData.key;
        state.apiUser.refreshToken = freshData.refreshToken;
        localStorage.setItem('adminUser', JSON.stringify(state.apiUser));
    }
};

const actions = {
    async login({ commit, dispatch }, {email, password, redirect, q, params}){
        try {
            //clean if there is a created user
            commit("setApiUser", null);
            localStorage.removeItem('adminUser');

            const data = { email, password };

            console.log(data);

            const apiUser = await requester.Post("/login", false, data);

            console.log(apiUser);

            commit("setApiUser", apiUser.data ? apiUser.data : apiUser);

            if(redirect){
                //redirect to required page
                router.push({ name: redirect, query: JSON.parse(q), params: JSON.parse(params) });
            }else{
                //redirect to home
                router.push({ name: "home" });
            }
        } catch (error) {
            console.log(error);
        }
    },
    async logout({ commit }){
        try {
            commit("setApiUser", null);
            localStorage.removeItem('adminUser');  
        } catch (error) {
            console.log({error});
        }
    },
    async refreshToken({ commit }){
        const data = {
            token: state.apiUser.token,
            refreshToken: state.apiUser.refreshToken
        };
        const freshData = await requester.Post("/refresh-token", false, data, {
            isPublic: true,
            "Access-Control-Expose-Headers": "isPublic"
        });

        if(freshData.data.error == "decryptError"){
            const decrypted = JSON.parse(encrypter.AES.decrypt(
                freshData.data.data,
                process.env.VUE_APP_CRIPTO_KEY
            ).toString(encrypter.enc.Utf8));
            commit("setRefreshKeys", decrypted);
        }else{
            commit("setRefreshKeys", freshData.data);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
