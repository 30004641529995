<template>
  <div class="home">
    <h1>Page Not Found 404</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

