import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
    faUser, 
    faFlag, 
    faPhone, 
    faMusic, 
    faSearch, 
    faHome, 
    faChevronLeft, 
    faChevronRight,
    faChevronUp, 
    faChevronDown,
    faArrowRightFromBracket, 
    faList, 
    faMicrophone, 
    faLayerGroup,
    faPlay,
    faCirclePlay,
    faPause,
    faCirclePause,
    faForwardStep,
    faBackwardStep,
    faMaximize,
    faVolumeUp,
    faEye,
    faEyeSlash,
    faEllipsisVertical,
    faListOl,
    faSquarePlus,
    faBars,
    faEnvelope,
    faCircleXmark,
    faTriangleExclamation,
    faCircleInfo,
    faCircleCheck,
    faXmark,
    faClockRotateLeft,
    faDrum,
    faVolumeHigh,
    faStop,
    faSort,
    faSortAsc,
    faSortDown,
    faHandsClapping,
    faThumbsDown,
    faThumbsUp,
    faDemocrat,
    faFaceLaughSquint,
    faFaceLaughBeam,
    faAward,
    faStopwatch,
    faHandsBubbles,
    faBellConcierge,
    faFaceSmileWink,
    faSkullCrossbones,
    faFaceGrimace,
    faFaceFrown,
    faHandPeace,
    faPeopleLine,
    faForward,
    faCircleQuestion,
    faCircleUser,
    faPencil,
    faCreditCard,
    faEnvelopeOpen,
    faCopy,
    faArrowUpFromBracket,
    faRetweet

} from "@fortawesome/free-solid-svg-icons";

import { } from "@fortawesome/free-regular-svg-icons";

import { 
    faGoogle, 
    faApple,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faPhone, 
    faUser, 
    faFlag, 
    faMusic, 
    faSearch, 
    faApple, 
    faWhatsapp,
    faGoogle, 
    faHome, 
    faChevronLeft, 
    faChevronRight,
    faChevronUp, 
    faChevronDown,
    faArrowRightFromBracket, 
    faList, 
    faMicrophone, 
    faLayerGroup,
    faPlay,
    faCirclePlay,
    faPause,
    faCirclePause,
    faForwardStep,
    faBackwardStep,
    faMaximize,
    faVolumeUp,
    faEye,
    faEyeSlash,
    faEllipsisVertical,
    faListOl,
    faSquarePlus,
    faBars,
    faEnvelope,
    faCircleXmark,
    faTriangleExclamation,
    faCircleInfo,
    faCircleCheck,
    faXmark,
    faClockRotateLeft,
    faDrum,
    faStop,
    faVolumeHigh,
    faSort,
    faSortAsc,
    faSortDown,
    faHandsClapping,
    faPeopleLine,
    faThumbsDown,
    faThumbsUp,
    faDemocrat,
    faFaceLaughSquint,
    faFaceLaughBeam,
    faAward,
    faStopwatch,
    faHandsBubbles,
    faBellConcierge,
    faFaceSmileWink,
    faSkullCrossbones,
    faFaceGrimace,
    faFaceFrown,
    faHandPeace,
    faForward,
    faCircleQuestion,
    faPhone,
    faCircleUser,
    faPencil,
    faCreditCard,
    faEnvelopeOpen,
    faCopy,
    faArrowUpFromBracket,
    faRetweet
);

export default FontAwesomeIcon;