<template>
  <div class="home" v-on:scroll="onScroll">
    <div class="banner">
      <div class="banner-data">
        <va-avatar class="banner-img border-square" size="90px" :src="details.genero.image" />
        <div class="text">
          <p class="banner-title" style="padding-left:20px;">{{ details.genero.name }}</p>
          <p class="banner-span" style="padding-left:20px;">{{ details.count }} canciones</p>
        </div>
      </div>
    </div>
    <div class="general">
      <va-tabs v-model="currentTab" grow color="#c3c4f7">
        <template #tabs>
          <va-tab v-for="title in tabs" :name="title" :title="'Ir a ' + title" v-title :key="title" color="#c3c4f7">
            {{ title }}
          </va-tab>
        </template>
      </va-tabs>

      <div v-if="currentTab == tabs[0]" class="tab-content">
        <div class="edit-container">
          <div class="config">
            <div class="title">Edición</div>
            <va-input v-model="details.genero.name" class="input" label="Nombre"
              title="Cambiar nombre" v-title placeholder="Nombre" />
            <va-input v-model="details.genero.description" class="input" type="textarea"
              label="Descripción" title="Cambiar descipción" v-title placeholder="Descripción" />

            <div class="field c-1-3">
              <span class="va-title">Foto</span>
              <va-file-upload dropzone type="single" file-types="image/*" v-model:model-value="newImg"
                @file-added="checkFile()"
                @file-removed="checkFile()" hideFileList>
                <div class="upload-area">
                  <div class="hover-info">
                    Cambiar imagen
                  </div>
                  <img :src="image" width="100" height="100">
                </div>
              </va-file-upload>
            </div>
            <div class="field c-1-3">
              <span class="va-title">Rating</span>
              <va-counter :min="0" :max="100" :step="1" v-model="details.genero.rate"
                manual-input class="input" title="Cambiar rating" v-title />
            </div>
            <div class="field c-1-3">
              <span class="va-title">¿Activo?</span>
              <va-switch v-model="details.genero.active" title="Cambiar estado" v-title
                color="success" class="input" />
            </div>

            <va-button color="warning" class="btn" @click="saveGenre()">
              Guardar
            </va-button>
            <va-button color="danger" class="btn" @click="deleteGenre()">
              Eliminar
            </va-button>
          </div>
          <div class="preview">
            <div class="title">Vista Previa</div>
            <div class="app-bg ekiflex flex-hcenter">
              <div title="Ir al detalle del Género" v-title class="genre-card ekiflex flex-vcenter">
                <div class="genre-thumb">
                  <img :lazy-src="require('@/assets/background.png')" :src="image" />
                </div>
                <p class="genre-title">{{ details.genero.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentTab == tabs[1]" class="tab-content">
        <h1 class="section-title">Detalle</h1>
      </div>
    </div>
  </div>
</template>

<script>
import requester from '../../services/requester';
export default {
  name: 'GenreDetail',
  data() {
    return {
      tabs: ['EDITAR', 'DETALLE'],
      currentTab: 'EDITAR',
      details: {
        count: 0,
        genero: {
          name: "",
          id: ""
        }
      },
      id: this.$route.params.id,
      image: "",
      songsByGenre: [],
      songsPage: 1,
      isLoading: false,
      sortingOrder: null,
      sortBy: null,
      continueLoading: true
    };
  },

  methods: {
    async getDetail() {
      try {
        var data = await requester.Get("/get-genero/" + this.$route.params.id, true);
        console.log(data);
        if (data.status == 200) {
          this.details = data.data;
          this.$store.dispatch("utils/setUpCrumbDetail", { crumbLabel: this.details.genero.name });
          this.image = this.details.genero.image;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  created() {
    this.getDetail();
  },
}
</script>

<style lang="scss" src="./genres.scss" scoped></style>
