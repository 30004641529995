import { Lrc } from 'lrc-kit';
  
export function lineTimeParser(line) {
    const lyrics = [];
    const info = {};
    var content = "";

    line
    .trim()
    .split(/<|>/g)
    .forEach(function (line, index) {
        if(index % 2){
            //IMPAR
            lyrics.push({
                timestamp: parseTime(line),
                content: content,
            });
        }else{
            //PAR
            content = line;
        }
    });

    var lrc = new Lrc();
    lrc.lyrics = lyrics;
    lrc.info = info;
    return lrc;
}

// match `<00:32.36>`
export const REMOVE_TIME_REGEXP = /(<|\[)+(\d*:\d*.\d*)+(>|\])/g;

// match `[512:34.1] lyric content`
export const TIME_REGEXP = /^\s*(\d+)\s*:\s*(\d+(\s*[\.:]\s*\d+)?)\s*$/;

function parseTime(tag) {
    var matches = TIME_REGEXP.exec(tag);
    var minutes = parseFloat(matches[1]);
    var seconds = parseFloat(matches[2].replace(/\s+/g, '').replace(':', '.'));
    return minutes * 60 + seconds;
}
