<template>
  <div class="home general" v-on:scroll="onScroll">
    <h1 class="section-title">
      Lista de Clientes
    </h1>
      <va-data-table
          class="ekitable"
          :items="customers"
          :columns="columns"
          clickable
          no-data-html="<div class='no-data'>Cargando pistas...</div>"
          :sticky-header="true"
          :loading="false"
          allow-create
          loading-text=""
      >
        <template #headerPrepend>
          <tr>
            <th colspan="6">
              <va-input class="search-bar" title="Buscar Canciones" v-title placeholder="¿Qué cliente buscas (correo)?" v-on:keyup.enter="onEnter" v-on:focusout="onEnter" v-model="search">
                <template #prependInner>
                  <font-awesome-icon :icon="['fa-solid', 'search']" />
                </template>
              </va-input>
            </th>
          </tr>
        </template>
        <template #cell(createdAt)="{ rowData }">
          {{ rowData.createdAt.substring(0,10) }}
        </template>

        <template #cell(actions)="{ rowData }">
          <va-button
              color="info"
              :to="{ name: 'customerDetail', params: { id: rowData.id } }"
          >
            <font-awesome-icon :icon="['fa-solid', 'pencil']" />
          </va-button>
        </template>



      </va-data-table>
  </div>
</template>
<script>

import requester from "@/services/requester";

export default {
  data() {
    return {
      page: 1,
      search: "",
      isLoading: false,
      sortingOrder: null,
      sortBy: null,
      continueLoading: true,
      columns: [
        {key: 'fullname', sortable: false, label: 'name'},
        {key: 'email', sortable: false, label: 'email'},
        {key: 'celular', sortable: false, label: 'celular'},
        {key: 'createdAt', sortable: false, label: 'Fecha creación'},
        {key: 'actions', sortable: false, label: 'Acciones'}
      ],
      customers:[]
    }

  },
  name: "CustomerList",

  methods: {
    onEnter(){
      this.page = 1;
      this.getFromApi();
    },
    async getFromApi(isScroll = false){
      this.isLoading = true;

      const data = await requester.Get(`/get-customers/${this.page}/?search=${this.search}`, true);

      if (data.status === 200) {
        if(!isScroll) {
          this.customers = data.data.customers;
        }else{
          this.customers = this.customers.concat(data.data.customers);
        }
        if(data.data.customers.length>0) {
          this.page++;
          this.continueLoading = true;
        }
        else{
          this.continueLoading = false;
        }
      }
      this.isLoading = false;
    },
    onScroll(event) {
      if(!this.continueLoading){
        return;
      }
      if (!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getFromApi(true);
        }
      }
    },

  },
  created() {
    this.onEnter();
  }
}

</script>