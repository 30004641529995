<template>
  <div class="home general" v-on:scroll="onScroll">
    <h1 class="section-title">
      Lista de Webhooks
    </h1>

    <va-data-table
        class="ekitable"
        :items="webhooks"
        :columns="columns"
        clickable
        no-data-html="<div class='no-data'>Cargando pistas...</div>"
        :sticky-header="true"
        :loading="false"
        allow-create
        loading-text=""
    >
      <template #headerPrepend>
        <tr>
          <th colspan="6">
            <va-input class="search-bar" title="Buscar Canciones" v-title placeholder="¿Qué cliente buscas (correo)?" v-on:keyup.enter="onEnter" v-on:focusout="onEnter" v-model="search">
              <template #prependInner>
                <font-awesome-icon :icon="['fa-solid', 'search']" />
              </template>
            </va-input>
          </th>
        </tr>
      </template>
      <template #cell(orderDate)="{ rowData }">
        {{ rowData.orderDate.substring(0,10) }}
      </template>

      <template #cell(customer)="{ rowData }">
        <span v-if="rowData.registerFinishedAt">{{rowData.webhookUserLicense?rowData.webhookUserLicense.userLicenseUserInfo.fullname:''}}</span>
        <span v-else>Licencia sin activación</span>
      </template>

      <template #cell(actions)="{ rowData }">
        <va-button
            color="info"
            @click="clickAction('send',rowData.id)"
            v-if="!rowData.registerFinishedAt"
            title="Reenviar correo de activación"
            v-title
        >
          <font-awesome-icon :icon="['fa-solid', 'envelope-open']" />
        </va-button>
        <va-button
            color="info"
            @click="clickAction('copy',rowData.id)"
            v-if="!rowData.registerFinishedAt"
            class="ml-2"
            title="Copiar link de activación"
            v-title
        >
          <font-awesome-icon :icon="['fa-solid', 'copy']" />
        </va-button>

        <va-button
            color="info"
            @click="showConfirm(rowData)"
            v-if="!rowData.registerFinishedAt"
            class="ml-2"
            title="Activar licencia"
            v-title
        >
          <font-awesome-icon :icon="['fa-solid', 'arrow-up-from-bracket']" />
        </va-button>

        <va-button
            color="info"
            v-if="rowData.registerFinishedAt!==null && rowData.webhookUserLicense!==null"
            @click="showConfirmReasign(rowData)"
            class="ml-2"
            title="Reasignar licencia"
            v-title
        >
          <font-awesome-icon :icon="['fa-solid', 'retweet']" />
        </va-button>
      </template>
    </va-data-table>
    <VaModal
                    v-model="showModal"
                    ok-text="Aceptar"
                    cancelText="cancelar"
                    @ok="activateLicence"
                >
                <h3 class="va-h3">
                   ¿Activar Licencia?
                </h3>
                <p>
                    ¿Seguro activar la licencia del correo {{licenseEmail}}?
                </p>
               
                </VaModal>


                <VaModal
                    v-model="showModalReasing"
                    ok-text="Aceptar"
                    cancelText="cancelar"
                    @ok="reasignLicence"
                >
                <h3 class="va-h3">
                   ¿Reasignar Licencia?
                </h3>
                <p>
                    ¿Seguro reasignar la licencia del correo {{licenseEmail}}? Ingresa el nuevo correo de activación. 
                    Para reasignar la licencia, el nuevo cliente debe tener una cuenta creada en la aplicación.
                </p>
                <va-input
                        v-model="reasingEmail"
                        placeholder="Ingresa un correo"
                        style="width: 100%;"
                    />
               
                </VaModal>
  </div>
  
</template>
<script>
import requester from "@/services/requester";
import { useToast } from 'vuestic-ui'
const { init, close, closeAll } = useToast()

export default {
  data() {
    return {
      page: 1,
      isLoading:false,
      webhooks:[],
      continueLoading: true,
      search:'',
      columns:[
        {key: 'email', sortable: false, label: 'correo de compra'},
        {key: 'event', sortable: false, label: 'evento'},
        {key: 'orderDate', sortable: false, label: 'fecha de compra'},
        {key: 'productName', sortable: false, label: 'Nombre de licencia'},
        {key: 'customer', sortable: false, label: 'Nombre del cliente'},
        {key: 'webhookUserLicense.userLicenseUserInfo.email', sortable: false, label: 'correo de activación'},
        {key: 'actions', sortable: false, label: 'Acciones'}

      ],
      showModal: false,
      licenseEmail:'',
      activateLicenseId:null,
      showModalReasing: false,
      reasingEmail:''
    }
  },
  methods: {
    onEnter() {
      this.page = 1;
      this.getFromApi();
    },
    async getFromApi(isScroll = false){
      this.isLoading = true;

      const data = await requester.Get(`/get-webhooks/${this.page}/?search=${this.search}`, true);

      console.log(data.data.webhooks);

      if (data.status === 200) {
        if(!isScroll) {
          this.webhooks = data.data.webhooks;
        }else{
          this.webhooks = this.webhooks.concat(data.data.webhooks);
        }
        if(data.data.webhooks.length>0) {
          this.page++;
          this.continueLoading = true;
        }
        else{
          this.continueLoading = false;
        }
      }
      this.isLoading = false;
    },
    onScroll(event) {
      if(!this.continueLoading){
        return;
      }
      if (!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getFromApi(true);
        }
      }
    },
    async clickAction(action,id){
      try{
        const data = await requester.Get(`/get-action/${id}/${action}`, true);
        switch (action){
          case 'send':
            init('Correo enviado');
            break;
          case 'copy':
            await navigator.clipboard.writeText(data.data);
            init('Link copiado al portapapeles');
            break;
        }
        setTimeout(()=>{
          closeAll();
        },5000);
      }
      catch(e){

      }

    },
    async showConfirm(rowData){
      this.licenseEmail = rowData.email;
      this.activateLicenseId = rowData.id;
      this.showModal = true;
    },
    async activateLicence(){
      try{
        const data = await requester.Post(`/activate-license/${this.activateLicenseId}`, true);
        init('Licencia activada');
        this.showModal = false;
        setTimeout(()=>{
          closeAll();
          window.location.reload();
        },5000);
      }
      catch(e){

      }
    },
    async showConfirmReasign(rowData){
      this.licenseEmail = rowData.email;
      this.activateLicenseId = rowData.id;
      this.showModalReasing = true;
    },

    async reasignLicence(){
      try{
        const data = await requester.Post(`/reasign-license/${this.activateLicenseId}`, true,{email:this.reasingEmail});
        init('Licencia reasignada');
        this.showModal = false;
        setTimeout(()=>{
          closeAll();
          window.location.reload();
        },5000);
      }
      catch(e){
        init('no se pudo reasignar lal licencia');
      }
    },


  },
  created() {
    this.onEnter();
  },
  
}
</script>