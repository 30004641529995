import { createStore } from 'vuex';
import user from './user';
import utils from './utils';

const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    checkApiAuth({ dispatch, commit }) {
      const adminUser = localStorage.getItem('adminUser');
      if(adminUser){
        const parsedUser = JSON.parse(adminUser);
        commit("user/setApiUser", parsedUser);
      }
    },
  },
  modules: {
    user,
    utils
  }
});

export default store;

//on init app
store.dispatch('checkApiAuth');