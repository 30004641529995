const state = {
    crumbDetail: ""
}

const getters = {

};

const mutations = {
    setUpCrumbDetail(state, crumbLabel){
        state.crumbDetail = crumbLabel;
    }
};

const actions = {
    setUpCrumbDetail({ commit }, { crumbLabel }){
        commit('setUpCrumbDetail', crumbLabel);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
