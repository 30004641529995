<template>
  <VaCard>
    <VaCardTitle>Enviar Notificacion</VaCardTitle>
    <VaCardContent>
      <VaForm tag="form">
        <div class="edit-container">
          <div class="config">
            <va-input label="Titulo" required class="input" placeholder="Titulo" v-model="title" :rules="[(value) => (value && value.length > 0) || 'Requerido']"/>
            <va-input type="textarea" label="Contenido"  class="input" required placeholder="Contenido" v-model="message" :rules="[(value) => (value && value.length > 0) || 'Requerido']"/>
            <va-switch class="input"
              v-model="opSwitch"
              true-label="Para todos"
              false-label="Seleccionar"/>
            <va-select class="input" v-if="opSwitch == false"
              v-model="valueSearch"
              label="Lista de Usuarios"
              :options="optionSearch"
              text-by="fullname"
              multiple
              searchable
              highlight-matched-text
              @update-search="(value) => getList(value)">
          </va-select>
          </div>
        </div>
        <va-button type="submit" @click="handleSubmit()" class="btn">Enviar</va-button>
        <va-button type="submit" @click="clearInput()" color="danger" class="btn">Cancelar</va-button>        
      </VaForm>
    </VaCardContent>
  </VaCard>
</template>
<script>
import requester from "@/services/requester";

export default {
  name: 'Notification',
  data() {
    return {
      title: "",
      message: "",
      optionSearch: [],
      valueSearch: '',
      page: 1,
      search: "",
      opSwitch: true,
    }
  },
  
  mounted () {
    this.getList();
  },

  methods: {  
    async getList (name) {
      let searchValue = name ?? this.search;
      const list = await requester.Get(`/getAllUser/${this.page}/?search=${searchValue}`, true);
      if (list.status == 200) {
        this.optionSearch = list.data.customers;
      }
    },

    handleSubmit() {
      let dataSend = {
        title: this.title,
        message: this.message
      }

      if (this.valueSearch.length > 0) {
        dataSend.ids = this.valueSearch
      }

      if (dataSend.title && dataSend.message) {
        this.sendNotify(dataSend)
      }
    },

    clearInput () {
      this.title = '';
      this.message = '';
      this.valueSearch = '';
      this.opSwitch = true;
    },

    async sendNotify(data) {
      const res = await requester.Put('/sendNotification', true, data);
      if(res.status === 200){
        this.clearInput();
      }
    }
  }
}
</script>