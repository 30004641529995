<template>
    <va-breadcrumbs class="eki-breadcrumbs" color="#c3c4f7">
        <va-breadcrumbs-item v-for="crumb in $route.meta.breadCrumb" v-bind:key="crumb.label" :title="crumb.to ? ('Ir a '+crumb.label) : ''" v-title :label="crumb.label == 'eki-item-detail' ? crumbDetail : crumb.label" :to="crumb.to ?? null" />
    </va-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "bread-crumbs",

    computed: {
        ...mapState("utils", ["crumbDetail"]),
    },
};
</script>

<style lang="scss" src="./breadCrumbs.scss"></style>
