import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import FontAwesomeIcon from './fontawesome';
import {loadFonts} from './plugins/webfontloader';
import vuestic from './plugins/vuestic';
import './assets/css/global.scss';
import vTitle from 'vuejs-title'
import {createVuestic} from "vuestic-ui";
import 'vuestic-ui/css';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

loadFonts();

const app = createApp({
    extends: App
});

app
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(vTitle, { cssClass: "eki-tooltip", fontSize: "20px" })
    .use(router)
    .use(Toast)
    .use(createVuestic(vuestic.vuesticConfig))
    .mount('#app');
