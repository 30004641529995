import HomeView from '../views/home/HomeView.vue'
import AuthenticateView from '../views/auth/AuthenticateView.vue'
import MainTemplate from '../views/templates/MainTemplate.vue'
import SubroutesTemplate from '../views/templates/SubroutesTemplate.vue'
import NotFound from '../views/NotFound.vue'
import SongsList from '../views/songs/SongsList.vue'
import SongDetail from '../views/songs/SongDetail.vue'
import GenresList from '../views/genres/GenresList.vue'
import GenreDetail from '../views/genres/GenreDetail.vue'
import ArtistsList from '../views/artists/ArtistsList.vue'
import ArtistDetail from '../views/artists/ArtistDetail.vue'
import FaqsList from '../views/faqs/FaqsList.vue'
import CustomerList from '../views/customers/CustomerList.vue'
import CustomerDetail from '../views/customers/CustomerDetail.vue'
import WebhooksList from "@/views/webhooks/WebhooksList.vue";
import SongsTopList from '@/views/topSongs/SongsList.vue';
import BannersList from '@/views/banner/BannersList.vue';
import SendNotification from '@/views/notifications/SendNotification.vue';

const  routes = [
  {
    path: '/dash',
    name: 'dashboard',
    redirect: { name: 'home' },
    meta: { 
      requiresAuth: true
    },
    component: MainTemplate,
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: {
          breadCrumb: [
            {
              label: 'Inicio'
            }
          ]
        }
      },
      {
        path: 'songs',
        name: 'songs',
        redirect: { name: 'songsList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list/:q?',
            name: 'songsList',
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Canciones',
                }
              ]
            },
            component: SongsList,
          },
          {
            path: 'detail/:id',
            name: 'songDetail',
            component: SongDetail,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Canciones',
                  to: { name: 'songsList' }
                },
                {
                  label: 'eki-item-detail'
                }
              ]
            },
          },
        ]
      },
      {
        path: 'genres',
        name: 'genres',
        redirect: { name: 'genresList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'genresList',
            component: GenresList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Géneros',
                }
              ]
            },
          },
          {
            path: 'detail/:id',
            name: 'genreDetail',
            component: GenreDetail,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Géneros',
                  to: { name: 'genresList' }
                },
                {
                  label: 'eki-item-detail'
                }
              ]
            },
          },
        ]
      },
      {
        path: 'artists',
        name: 'artists',
        redirect: { name: 'artistsList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'artistsList',
            component: ArtistsList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Artistas',
                }
              ]
            },
          },
          {
            path: 'detail/:id',
            name: 'artistDetail',
            component: ArtistDetail,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Artistas',
                  to: { name: 'artistsList' }
                },
                {
                  label: 'eki-item-detail'
                }
              ]
            },
          },
        ]
      },


        //////////
      {
        path: 'customers',
        name: 'customers',
        redirect: { name: 'customerList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'customerList',
            component: CustomerList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Clientes',
                }
              ]
            },
          },
          {
            path: 'detail/:id',
            name: 'customerDetail',
            component: CustomerDetail,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Clientes',
                  to: { name: 'customerList' }
                },
                {
                  label: 'eki-item-detail'
                }
              ]
            },
          },
        ]
      },
        /////

      {
        path: 'webhooks',
        name: 'webhooks',
        redirect: { name: 'webhookList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'webhookList',
            component: WebhooksList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Webhooks',
                }
              ]
            },
          },
        ]
      },
        //////
      {
        path: 'songs-top',
        name: 'songs-top',
        redirect: { name: 'songsTopList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'songsTopList',
            component: SongsTopList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'Top Songs',
                }
              ]
            },
          }
        ]
      },

        //////////
      {
        path: 'faqs',
        name: 'faqs',
        redirect: { name: 'faqsList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'faqsList',
            component: FaqsList,
            meta:{
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: { name: 'home' }
                },
                {
                  label: 'FAQ\'s',
                }
              ]
            },
          }
        ]
      },
      {
        path: 'banners',
        name: 'banners',
        redirect: { name: 'bannersList' },
        component: SubroutesTemplate,
        children: [
          {
            path: 'list',
            name: 'bannersList',
            component: BannersList,
            meta: {
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: {
                    name: 'home'
                  }
                },
                {
                  label: 'Banners'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'notifications',
        name: 'notifications',
        redirect: {
          name: 'notification'
        },
        component: SubroutesTemplate,
        children: [
          {
            path: 'send',
            name: 'notification',
            component: SendNotification,
            meta: {
              breadCrumb: [
                {
                  label: 'Inicio',
                  to: {
                    name: 'home'
                  }
                },
                {
                  label: 'Notifications'
                }
              ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/authenticate/:redirect?',
    name: 'authenticate',
    component: AuthenticateView
  },
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { requiresAuth: true },
    component: NotFound
  },
]

export default routes;
