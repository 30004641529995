<template>
  <div class="home" v-on:scroll="onScroll">
    <div class="banner">
      <div class="banner-data">
        <va-avatar
          class="banner-img border-square"
          size="90px"
          :src="getImageUrl(this.image, details.name)"
        />
        <div class="text">
          <p class="banner-title" style="padding-left: 20px">
            {{ details.name }}
          </p>
          <p class="banner-span" style="padding-left: 20px">{{songsNumber}} canciones</p>
        </div>
        <font-awesome-icon @click="playArtist()" title="Reproducir Canciones del Artista" v-title class="play-icon" :icon="['fa-solid', 'play']" />
      </div>
    </div>
    <div class="general">
    </div>
  </div>
</template>

<script>
import requester from "../../services/requester";
export default {
  name: "ArtistDetail",
  data() {
    return {
      details: [],
      songsPage: 1,
      id: this.$route.params.id,
      image: "",
      songsByArtist: [],
      sortingOrder: null,
      sortBy: null,
      isLoading: false,
      continueLoading: true,
      songsNumber: 0
    };
  },

  methods: {

    async getDetail() {
      try {
        const data = await requester.Get(
          "/artista-detail/" + this.$route.params.id,
          true
        );
        if (data.status === 200) {
          const {artista, count} = data.data;;
          this.details = artista;
          this.$store.dispatch("utils/setUpCrumbDetail", { crumbLabel: this.details.name });
          this.image = data.data.image;
          this.songsNumber = count;
        }
      } catch (error) {
        console.log(error);
      }
    },

    getSongsByArtist() {
      try {
        this.songsPage = 1;
        this.getSongfromAPI();
      } catch (error) {
        console.log(error);
      }
    },

    playArtist(){
      for (let index = 0; index < this.songsByArtist.length; index++) {
        if(index === 0){
          this.$store.dispatch("player/addToQueueAndPlay", { song: this.songsByArtist[index] });
        }else{
          this.$store.dispatch("player/addToQueue", { song: this.songsByArtist[index] });
        }   
      }
    },

    onScroll(event){
      if(!this.continueLoading){
        //it won't continue requesting the API if last request was empty
        return;
      }
      if(!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getSongfromAPI(true);
        }
      }
    },
    async getSongfromAPI(isScroll=false){
      this.isLoading = true;
      const data = await requester.Get(`/songs-artista/${this.$route.params.id}/${this.songsPage}/?sortBy=${this.sortBy}&sortingOrder=${this.sortingOrder}` , true);
      if (data.status === 200) {
        this.songsByArtist = !isScroll?data.data.songs:this.songsByArtist.concat(data.data.songs);
      }
      if(data.data.songs.length>0){
        this.songsPage++;
        this.continueLoading = true;
      }
      else {
        this.continueLoading = false;
      }
      this.isLoading = false;
    },
    getImageUrl(currentUrl, title){
      return currentUrl && currentUrl!=='https://firebasestorage.googleapis.com/v0/b/ekinternacional-358f5.appspot.com/o/app-images%2Feki.jpg?alt=media&token=df226863-dedb-417d-987f-119a20659c15'?currentUrl:`https://ui-avatars.com/api/${title}`;
    },

    setSorting({sortBy, sortingOrder}){

      if(this.sortBy === sortBy && this.sortingOrder === sortingOrder) {
        return;
      }
      this.sortBy = sortBy;
      this.sortingOrder = sortingOrder;
      this.getSongsByArtist();
    },
  },

  created() {
    this.getDetail();
    this.getSongsByArtist();
  },
};
</script>

<style lang="scss" src="./artists.scss" scoped></style>
