<template>
  <div class="home general">
    <h1 class="section-title">Dashboar home Inicio</h1>
  </div>
</template>

<script>
import requester from '../../services/requester';

export default {
  name: 'HomeView',

  data() {
    return {
    };
  },

  methods: {
  },

  created() {
  },
}
</script>

<style lang="scss" src="./home.scss" scoped></style>
