<template>
  <div class="home">
    <div class="banner">
      <div class="banner-data">
        <va-avatar class="banner-img border-square" size="90px" :src="getImageUrl(this.image, details.title)" />
        <div class="text">
          <p class="banner-title" style="padding-left:20px;">{{ details.title }}</p>
          <p class="banner-span" style="padding-left:20px;">{{ details.artista.name }} - {{ details.genero.name }}</p>
          <p class="banner-span" style="padding-left:20px;">Duración: {{ details.time }}</p>
        </div>
        <font-awesome-icon @click="playSong()" title="Reproducir Canción" v-title class="play-icon" :icon="['fa-solid', 'play']" />
      </div>
    </div>
    <div class="general">
      <div class="lrc-text">{{ this.letra }}</div>
      <!-- <div class="lrc-text" v-bind:key="verso" v-for="verso in this.letra.split('\n')"> {{ verso }}</div> -->
    </div>
  </div>
</template>

<script>
import requester from '../../services/requester';
import { REMOVE_TIME_REGEXP } from '../../services/linelrc';

export default {
  name: 'SongDetail',

  data() {
    return {
      details: {
        title: '',
        artista: {
          name: ''
        },
        genero: {
          name: ''
        },
        time: '0:00',
      },
      id : this.$route.params.id,
      image:"",
      letra:"",
      codigoLetra:'',
      autoplay: this.$route.query.autoplay
    };
  },

  methods: {
    async getDetail(){
      try {
        var data = await requester.Get("/song-detail/"+this.$route.params.id, true);
        if(data.status == 200){
          this.details = data.data;
          this.image= data.data.image;
          this.codigoLetra = data.data.jwt;
          this.$store.dispatch("utils/setUpCrumbDetail", { crumbLabel: this.details.title });
          var url = `${process.env.VUE_APP_API_URL}/lyrics/${this.codigoLetra}`;
          this.getLyrics(url);
          if(this.autoplay==="true"){
            this.playSong();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    getLyrics(url){
      fetch(url, {
        headers:{
          'Content-Type': 'application/json',
          'authorization': 'Bearer '
        }
      }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            this.letra = response.replace(REMOVE_TIME_REGEXP, '');
          });
    },

    getImageUrl(currentUrl, title){
      return currentUrl && currentUrl!=='https://firebasestorage.googleapis.com/v0/b/ekinternacional-358f5.appspot.com/o/app-images%2Feki.jpg?alt=media&token=df226863-dedb-417d-987f-119a20659c15'?currentUrl:`https://ui-avatars.com/api/${title}`;
    },

    playSong(){
      this.$store.dispatch("player/playKeepingQueue", { song: {
        'art.description': this.details.artista.description,
        'art.id': this.details.artista.id,
        'art.image': this.details.artista.image,
        'art.name': this.details.artista.name,
        'gen.description': this.details.genero.description,
        'gen.id': this.details.genero.id,
        'gen.image': this.details.genero.image,
        'gen.name': this.details.genero.name,
        id: this.$route.params.id,
        image: this.details.image,
        num: this.details.songHistoryId,
        time: this.details.time,
        title: this.details.title,
      } });
    }
  },

  created() {
    this.getDetail();
  },
}
</script>

<style lang="scss" src="./songs.scss" scoped></style>


