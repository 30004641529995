<template>
  <div class="template">    
    <splitpanes :class="'resizable-panel'">
      <!-- 70 y 170 -->
      <pane :min-size="(windowWidth < 1000 ? (menuState ? calcPercent(170) : calcPercent(0)) : 15)" :max-size="(windowWidth < 1000 ? (menuState ? calcPercent(170) : calcPercent(0)) : 30)" :size="(windowWidth < 1000 ? (menuState ? calcPercent(170) : calcPercent(0)) : 15)">
        <div class="side-panel">
          <router-link class="logo" :to="{name: 'home'}" title="Ir al Inicio" v-title>
            <img class="panel-icon" :src="require('@/assets/logo.png')" alt="ekilogo.png">
          </router-link>
          <h1 class="admin-title">EK Administrador</h1>
          <nav class="panel-links hide-scroll scroll">
            <router-link class="nav-link" :to="{name: 'songs'}" title="Ir a la lista de Canciones" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'search']" />
              Canciones
            </router-link>
            <router-link class="nav-link" :to="{name: 'artists'}" title="Ir a la lista de Artistas" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'microphone']" />
              Artistas
            </router-link>
            <router-link class="nav-link" :to="{name: 'genres'}" title="Ir a la lista de Generos" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'layer-group']" />
              Generos
            </router-link>
            <router-link class="nav-link" :to="{name: 'customers'}" title="Ir a la lista de clientes" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'circle-user']" />
              Clientes
            </router-link>
            <router-link class="nav-link" :to="{name: 'webhooks'}" title="Ir a la lista de webhooks" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'credit-card']" />
              Webhooks
            </router-link>
            <router-link class="nav-link" :to="{name: 'songs-top'}" title="Ir a la lista de webhooks" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'music']" />
              Top canciones
            </router-link>
            <router-link class="nav-link" :to="{name: 'faqs'}" title="Ir a la lista de Preguntas Frecuentes" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'layer-group']" />
              FAQ's
            </router-link>
            <router-link class="nav-link" :to="{name: 'banners'}" title="Ir a la lista de Banners" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'layer-group']" />
              Banners
            </router-link>
            <router-link class="nav-link" :to="{name: 'notifications'}" title="Enviar Notificaiones" v-title>
              <font-awesome-icon class="link-icon" :icon="['fa-solid', 'flag']" />
              Notifications
            </router-link>
          </nav>
        </div>
      </pane>
      
      <pane class="main-panel" :max-size="(windowWidth < 1000 ? (menuState ? calcPercent(-170) : 100) : 85)" :size="(windowWidth < 1000 ? (menuState ? calcPercent(-170) : 100) : 85)">
        <div class="open-cover" v-if="windowWidth < 1000 && menuState" @click="switchDrawer()"></div>

        <div :class="'top-menu'">
          <div v-if="windowWidth > 1000" class="hamburguer">
          </div>

          <div v-if="windowWidth <= 1000" class="hamburguer">
            <font-awesome-icon @click="switchDrawer()" title="Abrir/Cerrar Menú" v-title class="nav-opt" :icon="['fa-solid', 'bars']" />
          </div>

          <va-dropdown  :offset="[0, -100]">
            <template #anchor>
              <div class="user-icon" title="Opciones de Usuario" v-title>
                <font-awesome-icon class="nav-opt" :icon="['fa-solid', 'user']" />
              </div>
            </template>

            <div class="ekimodal">
              <div title="Cerrar Sesión" v-title class="option" v-if="apiUser" @click="logout">
                Cerrar sesión
                <font-awesome-icon class="link-icon" :icon="['fa-solid', 'arrow-right-from-bracket']" />
              </div>              
            </div>
          </va-dropdown>
        </div>

        <div class="nav-controls colored ekiflex flex-vcenter flex-hbetween">
          <bread-crumbs></bread-crumbs>
        </div>

        <router-view class="main-panel-content hide-scroll scroll"/>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import {mapGetters, mapState} from 'vuex';
import 'splitpanes/dist/splitpanes.css'
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs";
import requester from '../../services/requester';
import store from "@/store";

export default {
  name: 'MainTemplate',

  components: {
    Splitpanes,
    Pane,
    BreadCrumbs
  },

  data() {
    return {
      showUserOpt: false,
      menuState: false,
      windowWidth: window.innerWidth,
      activeColor: 0,
      navColor: false
    };
  },

  watch: {
    $route() {
      this.navColor = this.$route.path.includes('detail') || this.$route.path.includes('profile');
    }
  },

  created() {
    //join to socket room
    this.navColor = this.$route.path.includes('detail') || this.$route.path.includes('profile');
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    calcPercent(desiredSize){
      if(desiredSize < 0){
        return 100 + (desiredSize * 100 / this.windowWidth);
      }else{
        return desiredSize * 100 / this.windowWidth;
      }
    },

    async logout(){
      try {
        await this.$store.dispatch("user/logout")
        this.$router.push({name: "authenticate"});
      } catch (error) {
        console.log(error.message);
      }
    },

    switchDrawer(){
      this.menuState = !this.menuState;
    },

    toggleUserOpts(){
      this.showUserOpt = !this.showUserOpt;
    },

    goBack(){
      if(this.$router.options.history.state.back != null){
        this.$router.go(-1);
      }
    },

    goForward(){
      if(this.$router.options.history.state.forward != null){
        this.$router.go(1);
      }
    },

    setCountDownInterval(counter){
      this.activeColor = counter;
      if(counter>=0){
        setTimeout(this.setCountDownInterval,1000,--counter);
      }
      else{
        this.activeColor = 0;
      }
    },

    async createPlaylist() {
      try {
        var data = await requester.Put("/playlist/add", true, {
          id: null,
          name: this.nombrePlaylist
        });

        if (data.status == 200) {
          this.showPlaylistModal = false;
          this.nombrePlaylist=""
        }
      } catch (error) {
        console.log(error);
      }
    },
    subscribeShortCodes(codes){
      document.addEventListener("keydown", function(event) {
        for(const c in codes){
          if(!codes[c].shortCode){
            continue;
          }
          if (event.ctrlKey && event.code === codes[c].shortCode)
          {
            event.preventDefault();
            store.dispatch("player/reproduceEffect", {src:codes[c].url});
          }
        }

      });
    },
  },

  computed: {
    ...mapState("user", ["apiUser"])
  },


}
</script>

<style lang="scss" src="./dashboard.scss" scoped></style>
