const vuesticConfig = {
    config: {
        colors: {
            variables: {
                primary: '#640899'
            }
        }
    }
};

export default {
    vuesticConfig
};